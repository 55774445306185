exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-hobbies-js": () => import("./../../../src/pages/hobbies.js" /* webpackChunkName: "component---src-pages-hobbies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolios-js": () => import("./../../../src/pages/portfolios.js" /* webpackChunkName: "component---src-pages-portfolios-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-work-history-js": () => import("./../../../src/pages/work-history.js" /* webpackChunkName: "component---src-pages-work-history-js" */),
  "component---src-templates-blogs-detail-js": () => import("./../../../src/templates/blogs-detail.js" /* webpackChunkName: "component---src-templates-blogs-detail-js" */),
  "component---src-templates-portfolios-detail-js": () => import("./../../../src/templates/portfolios-detail.js" /* webpackChunkName: "component---src-templates-portfolios-detail-js" */)
}

